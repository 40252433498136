@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
  font-family: 'Inter', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app {
  position: relative;
}

.container {
  position: relative;
  z-index: 1; /* Ensure the container is above the video */
}

.video-section {
  z-index: 0; /* Lower z-index to place the video below the container */
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  background: black;
  border-radius: 5px;
}
 
::-webkit-scrollbar-thumb {
  background: grey; 
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: grey; 
}

@media (max-width: 553px) {
  .steps {
    top: 50px
  }
}